import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import addLocationIcon from "../../public/assets/icons/icons/newLocation.png";
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from "../../context/AppContext";
import { useAccountContext } from 'src/context/AccountContext';
import { dismissItem } from 'src/components/NewUserChecklist/dismissItem';


const apiUrl = process.env.REACT_APP_API_URL;

const AddLocationModal = ({ open, onClose, onAddLocation, fetchLocations }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const {accountData, onboardingData, fetchOnboardingRecord } = useAccountContext();  
  const [item, setItem] = useState('addLocation');



  const [phoneTouched, setPhoneTouched] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();


  const clearForm = () => { 
    setName('');
    setEmail('');
    setPhone('');
    setNameTouched(false);
    setEmailTouched(false);
    setPhoneTouched(false);
  };


  useEffect(() => { 
    if (!open) {
      clearForm();
    }
  }, [open]);

  useEffect(() => {
    const validateForm = () => {
      const isValidName = name.trim() !== '';
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);
      setIsFormValid(isValidName && isValidEmail && isValidPhone);
    };

    validateForm();
  }, [name, email, phone]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2] ? ' ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameTouched(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setPhone(formatPhoneNumber(value));
    setPhoneTouched(true);
  };



  const handleUpdateChecklist = async () => { 
    const userId = user.sub;  
    if (accountData.onboarding_complete === false) { 
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      return;
     } else { 
        return;
      }
  }



  const handleAddLocationClick = async () => {
    if (!isFormValid) return;

  
    const locationData = {
      name,
      email,
      phone,
      auth0UserId: user.sub,
    };
    setIsLoading(true);
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      const response = await fetch(`${apiUrl}/api/locations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(locationData),
      });
  
      if (!response.ok) {
        throw new Error('Error adding location');
      }
      setIsLoading(false);

  
      const data = await response.json();
      showSnackBar('Location Added', true);
      clearForm();
      onAddLocation(data);
      handleUpdateChecklist();
      fetchLocations(); 
    } catch (error) {
      console.error('Error adding location:', error);
      setIsLoading(false);
      showSnackBar('Unable to add location. Please try again. ', false);

    }
  };


  

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "20px", } }}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={addLocationIcon}
          alt="addLocationIcon"
          style={{
            border: "1px solid #B0BEC5",
            width: "auto",
            height: "32px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          width: "510px",
          backgroundColor: "#FFFFFF",
          padding: "26px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography variant="h5" sx={{ color: "#48768C", mb: 2.5, fontWeight: 600, lineHeight:'-2%', letterSpacing:'-2%' }}>
          Add A New Location{" "}
        </Typography>
        <TextField
          error={!name.trim() && nameTouched}
          helperText={!name.trim() && nameTouched && "Please enter a valid name"}
          placeholder="Location Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
          sx={{ color: 'textSecondary', pb: 2, '& input': { width: '100%', textAlign: 'left',color: 'textSecondary' } }}
        />
        <TextField
          error={!validateEmail(email) && emailTouched}
          helperText={!validateEmail(email) && emailTouched && "Please enter a valid email"}
          placeholder="Location Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          sx={{ color: 'textSecondary', pb: 2, '& input': { width: '100%', textAlign: 'left',color: 'textSecondary' } }}
        />
        <TextField
          error={!validatePhoneNumber(phone) && phoneTouched}
          helperText={!validatePhoneNumber(phone) && phoneTouched && "Please enter a valid phone number"}
          placeholder="Location Phone"
          variant="outlined"
          fullWidth
          value={phone}
          onChange={handlePhoneChange}
          sx={{ color: 'textSecondary', pb: 2, '& input': { width: '100%', textAlign: 'left',color: 'textSecondary' } }}
        />
        <DialogActions
          sx={{
            pt: 2,
            backgroundColor: "#FFFFFF",
            width: '100%',
            mx: 'auto',
            my: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: '10px',
              fontSize: '16px',
              mb: 1,
              color: '#48768C',
              fontWeight: 500,
              height: '51px',
              width: '25%',
              backgroundColor: '#F2F9FF',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              letterSpacing: '0.2px',
              '&:hover': {
                backgroundColor: '#A5CADB',
                color: '#F0F0F0',
              },
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid || isLoading}
            sx={{
              borderRadius: '10px',
              letterSpacing: '0.1px',
              width: '80%',
              height: '51px',
              fontSize: '18px',
              mb: 1,
              fontWeight: 500,
              backgroundColor: '#48768C',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
              color: 'rgba(233, 242, 255, 0.99)',
              '&:hover': {
                backgroundColor: '#3B6B7D',
                color: 'rgba(233, 242, 255, 0.95)',
              },
            }}
            onClick={handleAddLocationClick}
            variant="contained"
          >
            {isLoading ? 'Adding...' : 'Add Location'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddLocationModal;
